<template>
  <div class="product_scrm_content">
    <div class="box_top">
      <Header class="page_hea" />
      <div class="top_tlite">
        <p class="title">
          蒜瓣SCRM
        </p>
        <p class="subtitle">
          专帮企业搞定大客户!
        </p>
        <div
          class="btn"
          @click="showDialog=true"
        >
          免费试用
        </div>
        <div
          class="btn btnright"
          @click="openUrl('https://www.suanbanyun.com/home')"
        >
          了解更多
        </div>
      </div>
      <div class="top_img">
        <img
          v-lazy="scrm_bg"
          alt=""
        >
      </div>
    </div>
    <div class="page_content">
      <div class="section bottom0">
        <p class="title">
          蒜瓣SCRM视频演示
        </p>
        <div class="content_box">
          <div class="video_wrp">
            <video
              ref="video"
              src="https://platform-public.oss-cn-beijing.aliyuncs.com/video/%E8%92%9C%E7%93%A3SCRM%EF%BC%8C%E4%B8%93%E5%B8%AE%E4%BC%81%E4%B8%9A%E6%90%9E%E5%AE%9A%E5%A4%A7%E5%AE%A2%E6%88%B7.mp4"
              preload="auto"
              controls="controls"
              class="video"
            />
            <div
              v-show="videoMask == 'pause'"
              class="video_mask"
              @click="videoPlay"
            >
              <img
                :src="play"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <p class="title">
          三大工具，助你成交！
        </p>
        <p class="subtitle short">
          蒜瓣SCRM是基于JEPaaS云平台搭建的企业级客户关系管理系统。它能够针对性地解决企业营销管理难题，快速提升企业销售业绩。
        </p>
        <div class="content_box swiper_1">
          <el-carousel
            indicator-position="outside"
            :arrow="'never'"
            :interval="2000"
          >
            <el-carousel-item
              v-for="(item, index) in swiper1"
              :key="index"
            >
              <img
                :src="item.img"
                alt=""
                style="width: 100%"
              >
              <div>{{ item.name }}</div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="title">
        <p>专业营销管理，智慧决策分析</p>
        <p>搞定大客户不是问题！</p>
      </div>
      <div class="section">
        <p class="title">
          一、营销管理
        </p>
        <div class="content_box">
          <div class="swiper_2">
            <el-carousel
              indicator-position="outside"
              :arrow="'never'"
              :interval="2000"
            >
              <el-carousel-item
                v-for="(swiper, index) in swiper2"
                :key="index"
              >
                <div
                  v-for="(item, i) in swiper"
                  :key="i"
                  class="swiper_content_wrp"
                >
                  <img
                    :src="item.img"
                    alt=""
                    style="width: 100%"
                  >
                  <div>{{ item.name }}</div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="tips mb-160">
            <div
              v-for="item in tip1"
              :key="item"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <p class="title">
          二、经营管理
        </p>
        <div class="content_box">
          <div class="jys">
            <div
              v-for="(item, index) in jys"
              :key="index"
            >
              <img
                :src="item.img"
                alt=""
                style="width: 100%"
              >
              <p>{{ item.text }}</p>
            </div>
          </div>
          <div class="tips mb-160">
            <div
              v-for="item in tip2"
              :key="item"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <p class="title">
          三、决策分析
        </p>
        <div class="content_box">
          <div class="swiper_3">
            <el-carousel
              indicator-position="outside"
              :arrow="'never'"
              :interval="2000"
            >
              <el-carousel-item
                v-for="(item, index) in swiper3"
                :key="index"
              >
                <div class="text">
                  <p>{{ item.text }}</p>
                  <p>{{ item.text1 }}</p>
                </div>
                <div class="img_wrp">
                  <img
                    :src="item.img"
                    alt=""
                    style="width: 100%"
                  >
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="tips">
            <div
              v-for="item in tip3"
              :key="item"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div class="section linear">
        <p class="title">
          四大服务，全方位保障<br>有实力，才更有底气
        </p>
        <div class="content_box">
          <div class="server">
            <div
              v-for="(item, index) in servers"
              :key="index"
            >
              <img
                :src="item.img"
                alt=""
              >
              <div>
                <p class="title">
                  {{ item.title }}
                </p>
                <p
                  class="subtitle"
                  v-html="item.text"
                />
              </div>
              <div
                class="hover"
                v-html="item.hover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <el-dialog
      title=""
      :visible.sync="showDialog"
      width="50%"
      :modal-append-to-body="false"
    >
      <dialogV />
    </el-dialog>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import scrm_bg from '@/assets/imgs/product/scrm/scrm_bg.jpg';
import dialogV from '@/components/dialogV.vue';
import swiper1_1 from '@/assets/imgs/product/scrm/swiper1_1.png';
import swiper1_2 from '@/assets/imgs/product/scrm/swiper1_2.png';
import swiper1_3 from '@/assets/imgs/product/scrm/swiper1_3.png';
import swiper2_1 from '@/assets/imgs/product/scrm/swiper2_1.png';
import swiper2_2 from '@/assets/imgs/product/scrm/swiper2_2.png';
import swiper2_3 from '@/assets/imgs/product/scrm/swiper2_3.png';
import swiper2_4 from '@/assets/imgs/product/scrm/swiper2_4.png';
import swiper3_1 from '@/assets/imgs/product/scrm/swiper3_1.png';
import swiper3_2 from '@/assets/imgs/product/scrm/swiper3_2.png';
import jy1 from '@/assets/imgs/product/scrm/jy1.png';
import jy2 from '@/assets/imgs/product/scrm/jy2.png';
import jy3 from '@/assets/imgs/product/scrm/jy3.png';
import server1 from '@/assets/imgs/product/scrm/server1.png';
import server2 from '@/assets/imgs/product/scrm/server2.png';
import server3 from '@/assets/imgs/product/scrm/server3.png';
import server4 from '@/assets/imgs/product/scrm/server4.png';
import play from '@/assets/imgs/video/play.png';

export default {
  name: 'ProScrmPage',
  components: {
    Header, Footer, dialogV,
  },
  data() {
    return {
      play,
      scrm_bg,
      showDialog: false,
      swiper1: [
        {
          name: '一图',
          img: swiper1_1,
        },
        {
          name: '两卡',
          img: swiper1_2,
        },
        {
          name: '四连环',
          img: swiper1_3,
        },
      ],
      swiper2: [
        [
          {
            name: '销售展板全局概览 随时掌控企业运行',
            img: swiper2_1,
          },
          {
            name: '实时记录销售进程 高效整合销售信息',
            img: swiper2_2,
          },
        ],
        [
          {
            name: '客户跟进清晰管理 充分利用客户资源',
            img: swiper2_3,
          },
          {
            name: '四连环协同助销售 全员营销合力促单',
            img: swiper2_4,
          },
        ],
      ],
      swiper3: [
        {
          text: '多维直观数据统计',
          text1: '全面分析促进决策',
          img: swiper3_1,
        },
        {
          text: '线索商机有效评估',
          text1: '销售异常及时预警',
          img: swiper3_2,
        },
      ],
      tip1: ['销售展板', '市场活动', '销售线索', '销售商机', '客户公海', '客户管理', '一网打尽卡', '客户画像图', '一剑封喉卡', '销售四连环', '四连环邀请卡', '客户联系人', '跟进记录', '客户关怀'],
      tip2: ['产品管理', '价格表', '报价单', '产品品牌宣传', '订单合同', '回款计划', '回款记录', '发票管理', '退货记录', '退款记录', '外勤管理'],
      tip3: ['营销数据统计', '经营数据统计', '客户分布统计', '经营报表', '销售漏斗'],
      jys: [
        {
          text: '产品资料统一管理',
          img: jy1,
        },
        {
          text: '销售事务及时提醒',
          img: jy2,
        },
        {
          text: '订单记录明确清晰',
          img: jy3,
        },
      ],
      servers: [
        {
          title: '1个专属顾问',
          text: '一对一专属顾问服务',
          hover: '专属顾问一对一服务，提供产品讲解、需求调研、解决方案以及指导产品使用等全方位服务。<br/>服务热线：400-0999-235<br/>产品咨询-需求梳理-解决方案-产品使用',
          img: server1,
        },
        {
          title: '2大全力支持',
          text: '百人技术团队支持<br/>销售能力提升',
          hover: '技术支持：百人技术团队维护，保障客户运营安全和数据安全<br>销售能力提升：定期顶级销售大师培训课，及线上营销销售管理课程学习。',
          img: server2,
        },
        {
          title: '3大承诺',
          text: '7*9小时专业客服在线<br/>顾问团快速响应<br/>终身免费升级',
          hover: '7*9专业服务：全天候在线客服，帮助解决各类客户产品问题。<br/>顾问团快束响应：专家顾问团快速响应，及时解决问题、反馈客户。<br/>终身免费升级：产品定期升级更新，客户使用终身免费升级。',
          img: server3,
        },
        {
          title: '4步快速上手',
          text: '4步快速部署产品，快速上手操作，更快地帮客户提高效率。',
          hover: '需求整合：根据客户需求，快速整合梳理。<br/>制定方案：根据需求快速给出产品功能解决方案。<br/>后台部署：快速落地部署产品。<br/>使用培训：培训客户快速上手使用产品，应用功能，提高效率。',
          img: server4,
        },
      ],
      videoMask: 'pause',
    };
  },
  mounted() {
    const that = this;
    const myVideo = document.getElementsByClassName('video')[0];
    myVideo.addEventListener('play', () => {
      that.$set(that, 'videoMask', 'play');
    });

    myVideo.addEventListener('pause', () => {
      that.$set(that, 'videoMask', 'pause');
    });
  },
  methods: {
    videoPlay() {
      this.$refs.video.play();
    },
    openUrl(url) {
      if (!url) return;
      window.open(url);
    },
  },
};
</script>

<style lang="less">
  .product_scrm_content {
    .box_top {
      width: 100%;
      height: 600px;
      background:linear-gradient(270deg,rgba(255,125,80,1) 0%,rgba(246,71,77,1) 100%);
      display: flex;
      justify-content: space-between;
      .top_tlite {
        padding: 160px 0 0 195px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        > p {
          padding-left: 180px;
        }
        > .title {
          font-size: 60px;
          margin: 0;
          text-align: left;
        }
        > .subtitle {
          font-size: 60px;
          margin: 20px 0 70px;
          // margin-top: 30px
        }
        > .btn {
          text-align: center;
          box-sizing: border-box;
          margin-left: 70px;
          // margin-top: 80px;
          width:200px;
          height:60px;
          line-height: 60px;
          border:2px solid rgba(255,255,255,1);
          background:linear-gradient(90deg,rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
          border-radius:6px;
          display: inline-block;
          cursor: pointer;
          &.btnright {
            background:linear-gradient(90deg,rgba(255,145,21,1) 0%,rgba(255,199,80,1) 100%);
            margin-left: 40px;
            border: 0;
          }
        }
      }
      .top_img {
        display: flex;
        align-items: flex-end;
        img {
          width: 794px;
          height: 515px;
          position: relative;
          left: -170px;
        }
      }
    }
    .page_content {
      text-align: center;
      >.title {
        font-size: 40px;
        color: #333333;
        text-align: center;
        background: #ECF3F9;
        padding: 80px 0;
        > p {
          margin: 0;
        }
      }
      .section {
        margin:0 auto;
        padding: 120px 0 100px;
        &.linear {
          background:linear-gradient(0deg,rgba(255,255,255,1) 0%,rgba(255,239,235,1) 100%);
        }
        &.bottom0 {
          padding-bottom: 0;
        }
        .title {
          font-size: 40px;
          color: #000000;
          text-align: center;
          font-weight:bold;
          margin-bottom: 80px;
        }
        .subtitle {
          font-size: 20px;
          color: #303336;
          text-align: center;
          margin: 80px auto 100px;
          &.short {
            width: 900px;
          }
        }
        .content_box {
          width: 1200px;
          margin: 0 auto;
          .video_wrp {
            width: 900px;
            height: 506px;
            margin: 0 auto;
            position: relative;
            .video {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              width: 900px;
              height: 506px;
              z-index: 1;
              object-fit: contain;
            }
            .video_mask {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: 2;
              background: rgba(0, 0, 0, .4);
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              > img {
                width: 80px;
              }
            }
          }
          &.swiper_1 {
            width: 940px;
          }
          .tips {
            margin-top: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            &.mb-160 {
              margin-bottom: 160px;
            }
            > div {
              margin: 10px;
              width:130px;
              height:46px;
              line-height: 46px;
              background:rgba(226,240,253,1);
              border-radius:23px;
              font-size: 16px;
              color: #303336;
              text-align: center;
            }
          }
          .jys {
            display: flex;
            align-items: center;
            justify-content: space-around;
            > div {
              width: 235px;
              > p {
                margin: 0;
                width: 235px;
                margin-top: 40px;
                font-size: 24px;
                color: #333333;
              }
            }
          }
          .server {
            display: flex;
            align-items: center;
            justify-content: center;
            > div {
              width: 270px;
              height: 400px;
              overflow: hidden;
              border-radius: 1px;
              position: relative;
              color: #ffffff;
              &:hover {
                >.hover {
                  top: 0;
                }
              }
              &:not(:first-child) {
                margin-left: 40px;
              }
              > img {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
              }
              > div {
                position: relative;
                top: 245px;
                right: 0;
                bottom: 40px;
                left: 0;
                padding: 0 40px;
                z-index: 2;
                > p {
                  margin: 0;
                  color: #ffffff;
                  text-align: left;
                }
                > .title {
                  font-size: 24px;
                }
                > .subtitle {
                  font-size: 18px;
                  margin-top: 10px;
                }
              }
              > .hover {
                position: absolute;
                top: 400px;
                right: 0;
                bottom: 0;
                left: 0;
                padding: 40px;
                font-size: 16px;
                border-radius: 10px;
                text-align: left;
                background:linear-gradient(215deg,rgba(255,120,84,.9) 0%,rgba(246,71,77,.9) 100%);
                transition: top 300ms;
              }
            }
          }
        }
      }
    }
  }
</style>
<style lang="less">
.product_scrm_content{
  .el-carousel__indicator.el-carousel__indicator--horizontal.is-active {
    > button {
      background: #FF3041;
    }
  }
  .swiper_1 {
    .el-carousel__container {
      height: 800px;
    }
  }
  .swiper_2 {
    .el-carousel__container {
      height: 500px;
      .el-carousel__item {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .swiper_3 {
    .el-carousel__container {
      height: 600px;
      .el-carousel__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .img_wrp {
          width: 822px;
        }
        .text {
          font-size: 30px;
          color: #303339;
          flex: 1;
          text-align: left;
          > p {
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
