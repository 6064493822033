<template>
  <!--注册弹出框-->
  <div class="paddingChange">
    <div
      class="font-20 weight text-center"
      style="color: #303336FF"
    >
      填写信息，获取蒜瓣SCRM免费试用名额
    </div>
    <div class="mt-20 text-center text-colorGray font-14">
      客服人员会尽快与您取得联系，安排试用
    </div>
    <div class="hrChange mt-20" />
    <div class="mt-40">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="70px"
        class="demo-ruleForm"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="姓名"
              prop="name"
            >
              <el-input
                v-model="ruleForm.name"
                placeholder="请输入您的姓名"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="职务"
              prop="duty"
            >
              <el-input
                v-model="ruleForm.duty"
                placeholder="请输入您的职务"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          class="mt-20"
          :gutter="20"
        >
          <el-col :span="12">
            <el-form-item
              label="电话"
              prop="phone"
            >
              <el-input
                v-model="ruleForm.phone"
                placeholder="请输入联系电话"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="邮箱"
              prop="email"
            >
              <el-input
                v-model="ruleForm.email"
                placeholder="请输入邮箱地址"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          class="mt-20"
          :gutter="20"
        >
          <el-col :span="24">
            <el-form-item
              label="公司"
              prop="company"
            >
              <el-input
                v-model="ruleForm.company"
                placeholder="请输入公司名称"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          class="mt-20 el_li"
          :gutter="20"
        >
          <el-col :span="24">
            <el-row>
              <el-col :span="8">
                <el-form-item
                  label="地址"
                  prop="provinceValue"
                >
                  <el-select
                    v-model="ruleForm.provinceValue"
                    placeholder="省份"
                    @change="provinceChange"
                  >
                    <el-option
                      v-for="(data,index) in province"
                      :key="index"
                      :label="data.label"
                      style="padding: 0 20px"
                      :value="index"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  class="text-center"
                  prop="cityValue"
                >
                  <el-select
                    v-model="ruleForm.cityValue"
                    :disabled="cityStatus"
                    placeholder="城市"
                    @change="cityChange"
                  >
                    <el-option
                      v-for="(data,index) in city"
                      :key="index"
                      :label="data.label"
                      style="padding: 0 20px"
                      :value="index"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="countyValue">
                  <el-select
                    v-model="ruleForm.countyValue"
                    :disabled="countyStatus"
                    placeholder="区县"
                    @change="countyChange"
                  >
                    <el-option
                      v-for="(data,index) in county"
                      :key="index"
                      :label="data.label"
                      style="padding: 0 20px"
                      :value="index"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row
          class="mt-20"
          :gutter="20"
        >
          <el-col :span="24">
            <el-form-item
              label="其他"
              prop="more"
            >
              <el-input
                v-model="ruleForm.more"
                type="textarea"
                :rows="5"
                placeholder="其他您想要告诉我们的"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <div
          class="buttonShen"
          @click="submitForm('ruleForm')"
        >
          申请试用
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import areac from '../assets/js/areac';

export default {
  name: 'DialogV',
  data() {
    return {
      prohibit: true,
      dialogChange: true,
      opValue: null,
      province: [],
      provinceIndex: null, // 省的下标
      city: [],
      cityIndex: null, // 城市的下标
      cityStatus: true,
      county: [],
      countyStatus: true,
      countyIndex: null, // 区县的下标
      /** ******************表单的验证************** */
      ruleForm: {
        name: '',
        duty: '',
        phone: '',
        email: '',
        company: '',
        more: '',
        provinceValue: '',
        provinceCode: '',
        cityValue: '',
        cityCode: '',
        countyValue: '',
        areaCode: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入您的联系电话', trigger: 'blur' },
        ],
        company: [
          { required: true, message: '请输入公司名称', trigger: 'blur' },
        ],
        provinceValue: [
          { required: true, message: '请选择您的省份', trigger: 'change' },
        ],
        cityValue: [
          { required: true, message: '请选择您的城市', trigger: 'change' },
        ],
        countyValue: [
          { required: true, message: '请选择您的区县', trigger: 'change' },
        ],
      },
    };
  },
  mounted() {
    // 获取城市联查
    this.getCity();
  },
  methods: {
    countyChange(val) {
      this.ruleForm.countyValue = this.county[val].label;
      this.ruleForm.areaCode = this.county[val].value;
    },
    // 点击选中市
    cityChange(val) {
      this.cityIndex = val;
      this.ruleForm.countyValue = '';
      this.ruleForm.areaCode = '';
      this.county = this.province[this.provinceIndex].children[val].children;
      this.countyStatus = false;
      this.ruleForm.cityValue = this.city[val].label;
      this.ruleForm.cityCode = this.city[val].value;
    },
    // 点击选中省
    provinceChange(val) {
      this.provinceIndex = val;
      this.county = '';
      this.ruleForm.cityValue = '';
      this.ruleForm.countyValue = '';
      this.ruleForm.cityCode = '';
      this.ruleForm.areaCode = '';
      this.city = this.province[val].children;
      this.cityStatus = false;
      this.ruleForm.provinceValue = this.province[val].label;
      this.ruleForm.provinceCode = this.province[val].value;
    },
    // 获取三级联查的各个地区
    getCity() {
      this.province = areac.data.filter(item => [
        item.value,
        item.label,
      ]);
    },
    // 关闭弹出框
    closeDialog(ff) {
      this.$refs[ff].resetFields();
    },
    // 申请试用
    submitForm(formName) {
      const self = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (self.prohibit) {
            self.prohibit = false;
            self.$ajax({
              url: '/je/product/crm/customer/saveSaleLead',
              data: {
                typeCode: 'SYZH',
                name: self.ruleForm.name,
                telePhone: self.ruleForm.phone,
                corporateName: self.ruleForm.company,
                provinceName: self.ruleForm.provinceValue,
                provinceCode: self.ruleForm.provinceCode,
                cityName: self.ruleForm.cityValue,
                cityCode: self.ruleForm.cityCode,
                areaName: self.ruleForm.countyValue,
                areaCode: self.ruleForm.areaCode,
                duties: self.ruleForm.duty,
                email: self.ruleForm.email,
                describe: self.ruleForm.more,
              },
            }).then(() => {
              self.prohibit = true;
              this.$message.success('提交成功请静候佳音!');
              self.ruleForm.name = '';
              self.ruleForm.phone = '';
              self.ruleForm.company = '';
              self.ruleForm.provinceValue = '';
              self.ruleForm.provinceCode = '';
              self.ruleForm.cityValue = '';
              self.ruleForm.cityCode = '';
              self.ruleForm.countyValue = '';
              self.ruleForm.areaCode = '';
              self.ruleForm.duty = '';
              self.ruleForm.email = '';
              self.ruleForm.more = '';
              this.$refs[formName].resetFields();
            }).catch(() => {

            });
          }
        } else {
          this.$message.error('请将所有必填信息填写完整');
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less">
  @media screen and (min-width: 1000px) and (max-width: 1300px) {
    .dialogDiv {
      .el-dialog {
        margin-top: 5vh !important;
      }
      .buttonShen {
        width: 200px!important;
        height: 50px!important;
        margin: 20px auto!important;
        line-height: 50px!important;
        font-size: 15px!important;
      }
    }
  }
  @media screen and (min-width: 1300px) and (max-width: 1500px) {
    .dialogDiv {
      .el-dialog {
        margin-top: 5vh !important;
      }
    }
  }
  .dialogDiv {
    .el-dialog {
      border-radius: 10px !important;
    }

    .el-dialog__header {
      text-align: center;
      color: #303336FF;
    }
  }
</style>
<style lang="less" scoped>
  @media screen and (min-width: 1000px) and (max-width: 1300px){
    .buttonShen {
      margin: 20px auto!important;
    }
  }
  @media screen and (min-width: 1300px) and (max-width: 1500px){
    .buttonShen {
      margin: 20px auto!important;
    }
  }
  .hrChange {
    width: 100%;
    height: 2px;
    border-top: 1px solid #AAAAAAFF;
    margin-top: 20px;
  }

  .buttonShen {
    width: 280px;
    height: 60px;
    background: #ff3041;

    // box-shadow: 2px 2px 10px 0px rgba(6, 191, 241, 0.4);
    border-radius: 30px;
    margin: 40px auto;
    text-align: center;
    line-height: 60px;
    font-size: 18px;
    color: white;
    cursor: pointer;
  }

  .paddingChange {
    padding: 0 55px;
  }
</style>

<style lang="less" scoped>
.mt-40 {
  margin-top: 40px;
}
.font-20 {
  font-size: 20px;
}
.weight {
  font-weight: bold;
}
.text-colorGray {
  color: #6a707b;
  margin-top: 20px;
}
</style>
